import { useEffect, useState, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import { ApiRequest, ShowLoader } from 'GlobalFunctions';
import getOrderDetails from '../Orders/getOrderDetails';
import StripeForm from './Utils/StripeForm';
import Shipping from './Utils/Shipping';
import ClientForm from './ClientForm';
import { enqueueSnackbar, useSnackbar } from "notistack";
import DefaultForm from './Utils/DefaultForm';
import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk';
import { ProjectConfig } from 'Global';
import POForm from './Utils/POForm';
import AutocompleteCities from './Utils/AutocompleteCities';
import Summary from './Summary';
import ProductList from './ProductList';

const defaultValues = {
    fname: "",
    lname: "",
    address: "",
    address2: "",
    postal: "",
    country: "",
    state: "",
    city: "",
    city_id: "",
    
    s_fname: "",
    s_lname: "",
    s_address: "",
    s_address2: "",
    s_postal: "",
    s_country: "",
    s_state: "",
    s_city: "",
    s_city_id: "",

    email: "",
    phone: "",
    isValidEmail: false,
    isValidPhone: false,
    
    sameShippingBilling: false,
    checkTerms: false,
    shipping_id: null,
    
}

export default function Checkout() {

    const [formValues, setFormValues] = useState(defaultValues);
    const [summary, setSummary] = useState<any>({});

    const [payment_info, setPaymentInfo] = useState<any>({});
    const [orderDetails, setOrderDetails] = useState<any>({});
    
    const [shippingCompleted, setShippingCompleted] = useState(false);


    const [forceUpdate, setForceUpdate] = useState(false);
    const paymentFormRootRef = useRef<any>(null); // Add this line

    /*=========================================================
    getSummary
    =========================================================*/
    const getSummary = async () => {

        ApiRequest({
            url: "/orders/summary",
            method: "get",
            query: {
                order_id: localStorage.getItem('order_id')
            },
            setResponse: (response: any) => {
                setSummary(response);
                getPaymentForm();
            }
        });
    }

    const handleProductsLoaded = () => {
        getSummary();
    };


    useEffect(() => {

        /*=========================================================
        CHECK IF ORDER ID EXISTS
        =========================================================*/

        const orderStatus = document.querySelector('[pc-step="status"]');
        orderStatus?.classList.add('hidden');

        //config order-status-back-button 
        const orderStatusBackButton = document.querySelector('[pc="status-back"]');
        orderStatusBackButton?.addEventListener("click", () => {
            window.location.href = '/';
        });


        /*=========================================================
        GET ORDER DETAILS
        =========================================================*/
        // getOrderDetails({
        //     page: "checkout",
        //     callback: async (cart: any) => {

        //         setOrderDetails(cart);
                
        //     }
        // });
        

        

        const paymentForm = document.querySelector('[pc="payment-form"]');
        
        // Remove the createRoot call from here
        // if (paymentForm) {
        //     const loaderContainer = document.createElement('div');
        //     const root = createRoot(loaderContainer);
        //     root.render(<Loader />);
        //     paymentForm.replaceChildren(loaderContainer);
        // }

        // Set up the accordion functionality
        setUpAccordion();

    }, []);

    


    /*=========================================================
    SET PAYMENT STATUS
    =========================================================*/
    const setPaymentStatus = (status: any, message: string) => {
        enqueueSnackbar(message, { variant: status });

        if(status === 'success') {
            
            const elements = document.querySelectorAll('[pc-hide=""]');
            elements.forEach(element => {
                element.classList.add('hidden');
            });

            //change order-status-title to "Order # ${order_id} completed"
            const orderStatusTitle = document.querySelector('[pc="status-heading"]');
            if(orderStatusTitle) {
                orderStatusTitle.textContent = `Order # ${localStorage.getItem('order_id')} completed`;
            }
            
            const orderStatus = document.querySelector('[pc-step="status"]');
            orderStatus?.classList.remove('hidden');

            /*DELETE LOCALSTORAGE*/
            localStorage.removeItem('order_id');
        }
    }

    /*=========================================================
    STRIPE FORM
    =========================================================*/
    useEffect(() => {
        if (Object.keys(payment_info).length !== 0) {
            const paymentForm = document.querySelector('[pc="payment-form"]');
            if (paymentForm) {
                if (!paymentFormRootRef.current) {
                    paymentFormRootRef.current = createRoot(paymentForm);
                }
                paymentFormRootRef.current.render(
                    <>
                        {summary.purchaseOrdersLogic? (
                            <POForm
                                payment_method={"purchase_order"}
                                setPaymentStatus={setPaymentStatus}
                                enqueueSnackbar={enqueueSnackbar}
                                ShowLoader={ShowLoader}
                            />
                        ) : (
                            <>
                            {payment_info.payment_method === 'stripe' && (
                            <StripeForm 
                                payment_settings={payment_info.payment_settings} 
                                setPaymentStatus={setPaymentStatus}
                            />
                            )}

                            {(
                                payment_info.payment_method === 'elavon' || 
                                payment_info.payment_method === 'authorize_net' ||
                                payment_info.payment_method === 'test_mode'
                            ) && (
                                <DefaultForm
                                    payment_method={payment_info.payment_method}
                                    setPaymentStatus={setPaymentStatus}
                                    enqueueSnackbar={enqueueSnackbar}
                                    ShowLoader={ShowLoader}
                                />
                            )}
                            

                            {payment_info.payment_method === 'square' &&
                                <PaymentForm 
                                    applicationId = {ProjectConfig.SQUARE_CLIENT_ID}
                                    cardTokenizeResponseReceived={async (token, verifiedBuyer) => {
                                        ShowLoader(true)

                                        await ApiRequest({
                                            url: "/payments/defaultCheckout",
                                            method: "post",
                                            body: {
                                                order_id: localStorage.getItem("order_id"),
                                                sourceId: token.token,
                                                payment_method: payment_info.payment_method
                                            },
                                            setResponse: (response: any) => {
                                                if(response.status === "success") {
                                                    setPaymentStatus('success', "Payment successful");
                                                }
                                                else {
                                                    setPaymentStatus('error', "Payment failed");    
                                                }
                                            }
                                        });
                                        ShowLoader(false)


                                    } }
                                    locationId='LBB7R4DKKTWBG'
                                >
                                    <CreditCard />
                                </PaymentForm>
                            }
                            </>
                        )}
                    </>
                );
            }
        }
    }, [payment_info]);

    const getPaymentForm = async () => {
        // ShowLoader(true);
        await ApiRequest({
            url: "/payments/getPaymentForm",
            method: "get",
            query: {
                order_id: localStorage.getItem('order_id'),
            },
            setResponse: async (response: any) => {
                ShowLoader(false);
                if(response.status==='error') {
                    enqueueSnackbar(response.message, { variant: 'error' });
                    return
                }
                
                setPaymentInfo(response);
                
                
            },
        });
        
    };

    const setUpAccordion = () => {
        const accordionItems = document.querySelectorAll('[pc-acc="item"]');
    
        // HIDE ALL EXCEPT THE FIRST
        accordionItems.forEach((item, index) => {
            const title = item.querySelector('[pc="accordion-heading"]') as HTMLElement;
            if (index !== 0) {
                Array.from(item.children).forEach(child => {
                    if (child !== title && child instanceof HTMLElement) {
                        child.style.display = 'none';
                    }
                });
                // title?.classList.add('disabled');
            } else {
                title?.classList.add('active');
            }
        });
    
        // CLICK EVENT FOR ACCORDION TITLE
        document.querySelectorAll('[pc="accordion-heading"]').forEach(title => {
            // Check if the title has the 'disabled' class to prevent click
            if (!title.classList.contains('disabled')) {
                title.addEventListener('click', event => {
                    const clickedTitle = event.currentTarget as HTMLElement;
                    const accordionItem = clickedTitle.closest('[pc-acc="item"]') as HTMLElement;
    
                    // Toggle visibility
                    if (clickedTitle.classList.contains('active')) {
                        clickedTitle.classList.remove('active');
                        Array.from(accordionItem.children).forEach(child => {
                            if (child !== clickedTitle && child instanceof HTMLElement) {
                                child.style.display = 'none';
                            }
                        });
                    } else {
                        clickedTitle.classList.add('active');
                        Array.from(accordionItem.children).forEach(child => {
                            if (child instanceof HTMLElement) {
                                child.style.display = 'block';
                            }
                        });
                    }
    
                    // Hide all siblings of the other accordion items
                    accordionItems.forEach(item => {
                        if (item !== accordionItem) {
                            const otherTitle = item.querySelector('[pc="accordion-heading"]') as HTMLElement;
                            otherTitle?.classList.remove('active');
                            Array.from(item.children).forEach(child => {
                                if (child !== otherTitle && child instanceof HTMLElement) {
                                    child.style.display = 'none';
                                }
                            });
                        }
                    });
                });
            }
        });
    
        // CLICK EVENT FOR ACCORDION BUTTON
        document.querySelectorAll('[pc-step="accordion-next"]').forEach(button => {
            button.addEventListener('click', event => {
                const accordionItem = (event.currentTarget as HTMLElement).closest('[pc-acc="item"]') as HTMLElement;
                const title = accordionItem.querySelector('[pc="accordion-heading"]') as HTMLElement;
    
                // Hide current item
                Array.from(accordionItem.children).forEach(child => {
                    if (child !== title && child instanceof HTMLElement) {
                        child.style.display = 'none';
                    }
                });
                title?.classList.remove('active');
    
                // Show the next item
                const nextAccordionItem = accordionItem.nextElementSibling as HTMLElement;
                if (nextAccordionItem) {
                    Array.from(nextAccordionItem.children).forEach(child => {
                        if (child instanceof HTMLElement) {
                            child.style.display = 'block';
                        }
                    });
                    nextAccordionItem.querySelector('[pc="accordion-heading"]')?.classList.add('active');
                }
            });
        });
    };


    const [allFieldsFilled, setAllFieldsFilled] = useState(false);
    const [incompleteFields, setIncompleteFields] = useState<string[]>([]);
    const isFormComplete = () => {

        let incompleteFields: string[] = [];


        const {
            fname,
            lname,
            address,
            postal,
            country,
            state,
            city,
            email,
            phone,
            checkTerms,
            sameShippingBilling,
            s_fname,
            s_lname,
            s_address,
            s_postal,
            s_country,
            s_state,
            s_city,
            isValidEmail,
            isValidPhone
        } = formValues;

        if (fname === "") incompleteFields.push("First Name");
        if (lname === "") incompleteFields.push("Last Name");
        if (address === "") incompleteFields.push("Address");
        if (postal === "") incompleteFields.push("Postal Code");
        if (country === "") incompleteFields.push("Country");
        if (state === "") incompleteFields.push("State");
        if (city === "") incompleteFields.push("City");
        if (!checkTerms) incompleteFields.push("Terms and Conditions");
        if (!isValidEmail) incompleteFields.push("Invalid Email");
        if (!isValidPhone) incompleteFields.push("Invalid Phone");

        if (!sameShippingBilling) {
            if (s_fname === "") incompleteFields.push("Shipping First Name");
            if (s_lname === "") incompleteFields.push("Shipping Last Name");
            if (s_address === "") incompleteFields.push("Shipping Address");
            if (s_postal === "") incompleteFields.push("Shipping Postal Code");
            if (s_country === "") incompleteFields.push("Shipping Country");
            if (s_state === "") incompleteFields.push("Shipping State");
            if (s_city === "") incompleteFields.push("Shipping City");
        }

        if (!shippingCompleted) incompleteFields.push("Shipping Method");

        setAllFieldsFilled(incompleteFields.length === 0);
        setIncompleteFields(incompleteFields);
        
        return {
            incompleteFields
        };
    };

    useEffect(() => {
        isFormComplete();
    }, [shippingCompleted]);

    
    


    useEffect(() => {
        enableDisableAccordion(allFieldsFilled);

        
    }, [allFieldsFilled]);


    const enableDisableAccordion = (allFieldsFilled: boolean) => {
        const accordion = document.querySelector('[pc-step="payment"]');
        if (accordion) {
            const title = accordion.querySelector('[pc="accordion-heading"]') as HTMLElement;
            const button = document.querySelector('[pc-step="accordion-next"]') as HTMLElement;
            if (title && button) {
                if (allFieldsFilled) {
                    title.classList.remove('disabled');
                    button.classList.remove('is-disabled');
                }
                else {
                    title.classList.add('disabled');
                    button.classList.add('is-disabled');
                }
            }
        }
    }

    return (
        <>  

            <ProductList 
                page="checkout" 
                getSummary={getSummary}
                onProductsLoaded={handleProductsLoaded} 
            />
            
            <ClientForm
                formValues={formValues}
                setFormValues={setFormValues}
                setForceUpdate={setForceUpdate} // Pass setForceUpdate
                isFormComplete={isFormComplete}
            />
            {/* {incompleteFields.length > 0 && (
                <div className="incomplete-fields">
                    <h3>Please complete the following fields:</h3>
                    <ul>
                        {incompleteFields.map((field, index) => (
                            <li key={index}>{field}</li>
                        ))}
                    </ul>
                </div>
            )} */}

            <Summary summary={summary} getSummary={getSummary} />

            <Shipping 
                setShippingCompleted={setShippingCompleted}
                formValues={formValues}
                getSummary={getSummary}
                forceUpdate={forceUpdate}
            />
            <div id="city_list">
                <AutocompleteCities 
                    formValues={formValues}
                    setFormValues={setFormValues}
                    pc_city="city"
                    pc_state="state"
                />
                <AutocompleteCities 
                    formValues={formValues}
                    setFormValues={setFormValues}
                    pc_city="s-city"
                    pc_state="s-state"
                />
            </div>
            
        </>
    )
}
