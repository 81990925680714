import { ApiRequest, ShowLoader } from "GlobalFunctions";
import {getProductQuantity} from "components/Bag/Bag";

/*=============================
GET ORDER DETAILS
=============================*/
export default async function getOrderDetails(params: any) {

    /*=============================
    CHECK IF ORDER ID EXISTS
    ==============================*/
    const order_id = localStorage.getItem('order_id');
    if (!order_id) {
        return;
    }

    // ShowLoader(true);

    const {
        page,
        callback = null
    } = params;

    let cart = null;

    await ApiRequest({
        url: "/orders/details",
        method: "get",
        query: {
            order_id,
            page
        },
        setResponse: async (response: any) => {
            if (response.status !== 'error') {
                cart = response;
                // await setSummary(cart);
                await renderCartProducts(cart.products);
            }
            
        },
    });

    if (callback) {
        await callback(cart)
    }

    ShowLoader(false);
    
    return cart;
}





const removeCoupon = async (coupon_code: string) => {
    ShowLoader(true);
    ApiRequest({
        url: "/orders/removeCoupon",
        method: "post",
        body: {
            order_id: localStorage.getItem('order_id')
        },
        setResponse: async (response: any) => {
            if (response.status === 'success') {
                window.location.reload();
            }
        },
    });
    ShowLoader(false);
}





/*=============================
RENDER CART PRODUCTS
=============================*/
const renderCartProducts = async (products: any) => {
    
    const cartProductsContainer = document.querySelector('[pc="product-list"]');
    if (!cartProductsContainer) return;

    const productModel = document.querySelector('[pc="product-item"]');
    if (!productModel) return;
    const productModelCloneForRemoval = productModel.cloneNode(true) as HTMLElement;

    cartProductsContainer.innerHTML = '';


    /* MAP PRODUCTS
    ==============================*/
    products.forEach((product: any) => {
        const productClone = productModelCloneForRemoval.cloneNode(true) as HTMLElement;

        productClone.querySelector('[pc="product-name"]')!.innerHTML = product.product_name;
        productClone.querySelector('[pc="product-price"]')!.innerHTML = '$' + Number(product.product_price).toLocaleString('en-US', { minimumFractionDigits: 2 });
        
        /* QUANTITY SELECT
        ==============================*/
        const quantityOptions = Array.from({ length: 10 }, (_, i) => i + 1).map(number => (
            `<option value="${number}">${number}</option>`
        )).join('');

        const quantitySelectElement = productClone.querySelector('[pc="product-quantity"]');

        if (quantitySelectElement) {

            const classes = quantitySelectElement?.getAttribute('class');

            if (quantitySelectElement.tagName.toLowerCase() !== 'select') {
                const quantitySelect = `<select pc="product-quantity" class="${classes}" name="product-quantity">
                    ${quantityOptions}
                </select>`;
                quantitySelectElement.outerHTML = quantitySelect;
            }

            const updatedQuantitySelectElement = productClone.querySelector('[pc="product-quantity"]') as HTMLSelectElement;
            updatedQuantitySelectElement.innerHTML = quantityOptions;
            updatedQuantitySelectElement.value = String(product.quantity);
            updatedQuantitySelectElement.addEventListener('change', async () => {
                await updateProductQuantity(product.order_product_id, updatedQuantitySelectElement.value);
            });
        } 


        /* IMAGE 
        ============================== */
        const productImageElement = productClone.querySelector('[pc="product-image"]') as HTMLImageElement;

        if (productImageElement) {
            productClone.querySelector('[pc="product-image"]')!.setAttribute('src', product.   product_image);
            productClone.querySelector('[pc="product-image"]')!.removeAttribute('srcset');
        }

        /* CUSTOM INPUTS
        ============================== */
        const custom_inputs = product.custom_inputs
            .map((attr: any) => `${attr.name}: ${attr.value}`)
            .join('<br>');

        const customInputsElement = productClone.querySelector('[pc="product-custom"]')

        if (customInputsElement) {
            customInputsElement.innerHTML = custom_inputs;
        }

        

        /* REMOVE BUTTON
        ============================== */
        const removeButtonElement = productClone.querySelector('[pc="remove-product"]') as HTMLElement;

        if (removeButtonElement) {
            removeButtonElement.addEventListener('click', async () => {
                await removeProduct(product.order_product_id);
            });
        }
        



        /* CHILD PRODUCT
        ============================== */
        if (product.parent_product_id > 0) {
            productClone.classList.add('product-child');
        }
        
        
        cartProductsContainer.appendChild(productClone);
    });
};




/*=============================
UPDATE PRODUCT QUANTITY
=============================*/
export async function updateProductQuantity(order_product_id: string, quantity: string) {
    
    ShowLoader(true);
    ApiRequest({
        url: "/orders/updateProductQuantity",
        method: "post",
        body: {
            order_product_id,
            quantity
        },
        setResponse: async (response: any) => {
            if (response.status === 'success') {
                await getOrderDetails("cart");
            }
        },
    });
    ShowLoader(false);
}


/*=============================
REMOVE PRODUCT
=============================*/
export async function removeProduct(order_product_id: string) {
        
    ShowLoader(true);
    ApiRequest({
        url: "/orders/removeProduct",
        method: "post",
        body: {
            order_product_id,
            order_id: localStorage.getItem('order_id')
        },
        setResponse: async (response: any) => {
            if (response.status === 'success') {
                getProductQuantity();
                await getOrderDetails("cart");
            }
        },
    });
    ShowLoader(false);
}