import { useEffect } from "react";
import { ApiRequest, ShowLoader } from "GlobalFunctions";

export default function Summary(props: any) {

    
    const {
        summary,
        getSummary
    } = props;

    useEffect(() => {
        
        setSummary();
    }, [summary]);


    const removeCoupon = async (coupon_code: string) => {
        ShowLoader(true);
        ApiRequest({
            url: "/orders/removeCoupon",
            method: "post",
            body: {
                order_id: localStorage.getItem('order_id')
            },
            setResponse: async (response: any) => {
                if (response.status === 'success') {
                    window.location.reload();
                }
            },
        });
        ShowLoader(false);
    }


    const setSummary = async () => {
        const subtotalElement = document.querySelector('[pc="summary-subtotal"]');
        if (subtotalElement) {
            subtotalElement.innerHTML = '$' + Number(summary.subtotal_price).toLocaleString('en-US', { minimumFractionDigits: 2 });
        }
    
        const taxElement = document.querySelector('[pc="summary-tax"]');
        if (taxElement) {
            taxElement.innerHTML = '$' + Number(summary.total_tax).toLocaleString('en-US', { minimumFractionDigits: 2 });
        }
    
        const deliveryElement = document.querySelector('[pc="summary-delivery"]');
        if (deliveryElement) {
            deliveryElement.innerHTML = '$' + Number(summary.shipping_price).toLocaleString('en-US', { minimumFractionDigits: 2 });
        }
    

        if (summary.coupon_id && Number(summary.coupon_id) != 0) {
            
            const discountElement = document.querySelector('[pc="summary-discount-value"]');
            if (discountElement) {
                discountElement.innerHTML = '$' + Number(summary.total_discount).toLocaleString('en-US', { minimumFractionDigits: 2 });
            }
    
            const coupon_code = summary.coupon_code;
            const summary_discount_label = document.querySelector('[pc="summary-discount-label"]');
            if (summary_discount_label) {
                // Eliminar el contenido previo si ya existía
                summary_discount_label.innerHTML = `
                    Discount <span 
                    class='coupon-chip'
                    >${coupon_code} 
                    <a
                    class='remove-coupon'
                    href="#" style="cursor: pointer; margin-left: 5px;">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 48 48" style="enable-background:new 0 0 48 48;" xml:space="preserve">
                    <path d="M45.8,0c-0.6,0-1.1,0.2-1.5,0.6L24,21L3.6,0.7C3.2,0.2,2.7,0,2.1,0C1,0,0,1,0,2.1c0,0.6,0.2,1.1,0.6,1.5L21,24L0.7,44.4  c-0.8,0.8-0.9,2.1-0.1,3s2.1,0.9,3,0.1c0,0,0,0,0.1-0.1L24,27l20.4,20.4c0.8,0.8,2.1,0.9,3,0.1s0.9-2.1,0.1-3c0,0,0,0-0.1-0.1L27,24  L47.3,3.6c0.8-0.8,0.9-2.1,0.1-3C47,0.2,46.4,0,45.8,0z"/>
                    </svg>
                    </a></span>
                `;
    
                // Agregar el listener al botón "x"
                const removeLink = summary_discount_label.querySelector('a');
                if (removeLink) {
                    removeLink.addEventListener('click', (e) => {
                        e.preventDefault();
                        removeCoupon(coupon_code);
                    });
                }
            }
        } 
    
        const totalElement = document.querySelector('[pc="summary-total"]');
        if (totalElement) {
            totalElement.innerHTML = '$' + Number(summary.total_price).toLocaleString('en-US', { minimumFractionDigits: 2 });
        }
    };

    return null
}