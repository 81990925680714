import * as _ from 'lodash';
import { useEffect, useState, useRef } from 'react';
import { ApiRequest } from 'GlobalFunctions';

export const validateEmail = (email: string): boolean => {
    // Implement full email validation
    const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/;
    return re.test(email);
};

export default function ClientForm(props: any) {

    const {
        formValues,
        setFormValues,
        isFormComplete = () => {
            return {
                allFieldsFilled: false,
                incompleteFields: [],
            }
        },
    } = props;

    const [isInitialized, setIsInitialized] = useState(false);
    const hasLoadedInitially = useRef(false);

    useEffect(() => {
        if (isInitialized) {
            isFormComplete();
        }
    }, [isInitialized]);

    // Estados para almacenar países y estados
    const [countries, setCountries] = useState<any[]>([]);
    const [states, setStates] = useState<any>({}); // { country_id: [states] }
    /*=========================================================
    FETCH DATA ON COMPONENT MOUNT
    =========================================================*/
    useEffect(() => {
        const fetchData = async () => {
            await getCountries(); 
            await getClientInfo(); 
        };
        fetchData();
        initializeDisabledFields();
    }, []);

    /*=========================================================
    GET COUNTRIES
    =========================================================*/
    const getCountries = () => {
        return new Promise<void>((resolve, reject) => {
            ApiRequest({
                url: '/countries/getCountries',
                method: 'GET',
                setResponse: (response: any) => {
                    setCountries(response);
                    fillCountrySelect('country', response);
                    fillCountrySelect('s-country', response);
                    resolve(); // Resolve the promise after countries are set
                }
            });
        });
    };

    const fillCountrySelect = (pcAttribute: string, countries: any[]) => {
        const selectElement = document.querySelector(`[pc="${pcAttribute}"]`) as HTMLSelectElement;
        if (selectElement) {
            countries.forEach((country: any) => {
                const option = document.createElement('option');
                option.value = country.country_id;
                option.innerHTML = country.country_name;
                selectElement.appendChild(option);
            });
        }
    };

    const clearStateSelect = (selectElement: HTMLSelectElement) => {
        const stateSelect = getStateSelect(selectElement);
        if (stateSelect) {
            stateSelect.innerHTML = '<option value=" ">Select State</option>'; // Limpiar y agregar opción por defecto
        }
    };

    /*=========================================================
    GET REGIONS (STATES) FOR A COUNTRY
    =========================================================*/
    const getRegions = (country_id: any, selectElement: HTMLSelectElement, selectedRegionId?: any) => {
        return new Promise<void>((resolve, reject) => {
            ApiRequest({
                url: '/countries/getRegions',
                method: 'GET',
                query: {
                    country_id
                },
                setResponse: async (response: any) => {
                    setStates((prevStates: any) => ({
                        ...prevStates,
                        [country_id]: response.list
                    }));


                    const stateSelect = getStateSelect(selectElement);
                    if (stateSelect) {
                        // Limpiar estados anteriores y agregar opción por defecto
                        stateSelect.innerHTML = '<option value="">Select State</option>';
                        response.list.forEach((state: any) => {
                            const option = document.createElement('option');
                            option.value = state.region_id;
                            option.innerHTML = state.region_name;
                            stateSelect.appendChild(option);
                        });

                        if (selectedRegionId) {
                            stateSelect.value = selectedRegionId.toString(); // Set the value after options are added
                            const event = new Event('change', { bubbles: true });
                            stateSelect.dispatchEvent(event);
                        }
                    }
                    resolve(); // Resolve after states are set
                }
            });
        });
    };

    /*=========================================================
    GET CLIENT INFO
    =========================================================*/
    const getClientInfo = () => {
        return new Promise<void>((resolve, reject) => {
            ApiRequest({
                url: '/orders/clientInfo',
                method: 'GET',
                query: {
                    order_id: localStorage.getItem('order_id')
                },
                setResponse: async (response: any) => {
                    await SetClientInfo(response);
                    resolve(); // Resolve the promise after client info is set
                }
            });
        });
    };

    /*=========================================================
    SET CLIENT INFO
    =========================================================*/
    const SetClientInfo = async (data: any) => {
        // Validate email and phone
        const isValidEmail = validateEmail(data.email);
        const isValidPhone = validatePhone(data.phone);

        setFormValues((prevFormValues: any) => ({ 
            ...prevFormValues, 
            ...data,
            isValidEmail,
            isValidPhone
        }));


        const elements = [
            { key: 'fname', selector: 'input[pc="fname"]' },
            { key: 'lname', selector: 'input[pc="lname"]' },
            { key: 'address', selector: 'input[pc="address"]' },
            { key: 'address2', selector: 'input[pc="address2"]' },
            { key: 'postal', selector: 'input[pc="postal"]' },
            { key: 'city', selector: 'input[pc="city"]' },

            { key: 's_fname', selector: 'input[pc="s-fname"]' },
            { key: 's_lname', selector: 'input[pc="s-lname"]' },
            { key: 's_address', selector: 'input[pc="s-address"]' },
            { key: 's_address2', selector: 'input[pc="s-address2"]' },
            { key: 's_postal', selector: 'input[pc="s-postal"]' },
            { key: 's_city', selector: 'input[pc="s-city"]' },
            { key: 's_country', selector: 'select[pc="s-country"]' },

            { key: 'email', selector: 'input[pc="email"]' },
            { key: 'phone', selector: 'input[pc="phone"]' },
        ];

        // Actualiza los valores de los inputs
        elements.forEach(({ key, selector }) => {
            const element = document.querySelector(selector) as HTMLInputElement | HTMLSelectElement;
            if (element && data[key] !== undefined && data[key] !== null && data[key] !== '' && data[key] !== 0) {
                element.value = data[key];
                const event = new Event('input', { bubbles: true });
                element.dispatchEvent(event);
            }
        });

        

        /*=========================================================
        SET COUNTRY AND STATE (123)
        =========================================================*/
        if (data.country) {
            const billingCountrySelect = document.querySelector('select[pc="country"]') as HTMLSelectElement;
            if (billingCountrySelect) {
                billingCountrySelect.value = data.country;
                await getRegions(data.country, billingCountrySelect, data.state); // Pass data.state as selectedRegionId
            }
        }

        if (data.s_country) {
            const shippingCountrySelect = document.querySelector('select[pc="s-country"]') as HTMLSelectElement;
            if (shippingCountrySelect) {
                shippingCountrySelect.value = data.s_country;
                await getRegions(data.s_country, shippingCountrySelect, data.s_state); // Pass data.s_state as selectedRegionId
            }
        }


        const sameShippingBilling = document.querySelector('input[pc="same-as"]') as HTMLInputElement;
        
        if (sameShippingBilling && data.sameShippingBilling !== undefined) {
            sameShippingBilling.checked = data.sameShippingBilling;
            if (data.sameShippingBilling) {
                const event = new Event('change', { bubbles: true });
                sameShippingBilling.dispatchEvent(event);
    
                const shippingFieldsContainer = document.querySelector('[pc="shipping-information"]');
                if (shippingFieldsContainer) {
                    shippingFieldsContainer.classList.add('hidden');
                }
            }
            
        }

        /*=========================================================
        CHECK TERMS
        =========================================================*/
        const checkTerms = document.querySelector('input[pc="terms"]') as HTMLInputElement;
        if (checkTerms && data.checkTerms !== undefined) {

            if (data.checkTerms){
                checkTerms.checked = data.checkTerms;
                const event = new Event('change', { bubbles: true });
                checkTerms.dispatchEvent(event);
            }
        }

        await validateAddressFields(data);
        

        setIsInitialized(true);
        attachEventListeners();
    };

    /*=========================================================
    UPDATE INFO (sin debounce)
    =========================================================*/
    const updateInfo = async () => {
        await ApiRequest({
            url: '/orders/updateOrderInfo',
            method: 'post',
            body: {
                ...formValues,
                order_id: localStorage.getItem('order_id')
            },
            setResponse: async (response: any) => {
                props.setForceUpdate((prev: boolean) => !prev); 
                isFormComplete();
            }
        }); 
    };

    useEffect(() => {
        if (isInitialized && hasLoadedInitially.current) {
            const debouncedUpdate = _.debounce(updateInfo, 500);
            debouncedUpdate();

            return () => {
                debouncedUpdate.cancel();
            };
        } else if (isInitialized) {
            hasLoadedInitially.current = true;
        }
    }, [formValues]);

    useEffect(() => {
        // Synchronize billing city input
        const billingCityInput = document.querySelector('input[pc="city"]') as HTMLInputElement;
        if (billingCityInput) {
            billingCityInput.value = formValues.city || '';
        }

        // Synchronize shipping city input
        const shippingCityInput = document.querySelector('input[pc="s-city"]') as HTMLInputElement;
        if (shippingCityInput) {
            shippingCityInput.value = formValues.s_city || '';
        }
    }, [formValues.city, formValues.s_city]);

    /*=========================================================
    ATTACH EVENT LISTENERS
    =========================================================*/
    const handleFocusOut = (event: Event) => {
        const input = event.target as HTMLInputElement | HTMLSelectElement;
        const pcAttribute = input.getAttribute('pc');
        if (pcAttribute) {
            if (input.value.trim() === '' || input.tagName === 'SELECT') {
                // Obtiene los estilos de :focus y los aplica a la clase focused
                const computedStyles = window.getComputedStyle(input);
                const stylesToClone = ['outline', 'border', 'background-color', 'color', 'box-shadow'];

                stylesToClone.forEach(prop => {
                    input.style.setProperty(prop, computedStyles.getPropertyValue(prop));
                });

                input.classList.add('focused');
            } else {
                input.classList.remove('focused');
                // Remove the applied styles
                input.style.removeProperty('outline');
                input.style.removeProperty('border');
                input.style.removeProperty('background-color');
                input.style.removeProperty('color');
                input.style.removeProperty('box-shadow');
            }
        }
    };
    
    const attachEventListeners = () => {
        const inputs = document.querySelectorAll('input[pc]');
        inputs.forEach(input => {
            const dataPc = input.getAttribute('pc');
            if (dataPc && dataPc !== 'city' && dataPc !== 's-city') {
                input.addEventListener('input', handleInputChange);
                input.addEventListener('focusout', handleFocusOut);
                input.addEventListener('focus', () => input.classList.remove('focused'));
            }
        });
    
        const selects = document.querySelectorAll('select[pc]');
        selects.forEach(select => {
            select.addEventListener('change', handleSelectChange);
            select.addEventListener('focusout', handleFocusOut);
            select.addEventListener('focus', () => select.classList.remove('focused'));
        });
    
        const checkbox = document.querySelector('input[pc="same-as"]');
        if (checkbox) {
            checkbox.addEventListener('change', handleSameAsChange);
            checkbox.addEventListener('focusout', handleFocusOut);
            checkbox.addEventListener('focus', () => checkbox.classList.remove('focused'));
        }
    
        const terms = document.querySelector('input[pc="terms"]');
        if (terms) {
            terms.addEventListener('change', handleTermsChange);
            terms.addEventListener('focusout', handleFocusOut);
            terms.addEventListener('focus', () => terms.classList.remove('focused'));
        }
    };
    

    /*=========================================================
    HANDLE INPUT CHANGE
    =========================================================*/
    const validatePhone = (phone: string): boolean => {
        const re = /^\+?[1-9]\d{1,14}$/;
        return re.test(phone);
    };

    const handleInputChange = (event: Event) => {
        const pc = (event.target as HTMLInputElement).getAttribute('pc')?.replace('-', '_');
        if (pc) {
            const value = (event.target as HTMLInputElement).value;

            let updates: any = { [pc]: value };

            // If email field changes, validate email
            if (pc === 'email') {
                updates.isValidEmail = validateEmail(value);
            }

            // If phone field changes, validate phone
            if (pc === 'phone') {
                updates.isValidPhone = validatePhone(value);
            }
            setFormValues((prevFormValues: any) => ({ 
                ...prevFormValues, 
                ...updates 
            }));
        }
    };

    /*=========================================================
    HANDLE SELECT CHANGE
    =========================================================*/
    const handleSelectChange = async (event: Event) => {
        const pc = (event.target as HTMLSelectElement).getAttribute('pc')?.replace('-', '_');
        if (pc) {
            const value = (event.target as HTMLSelectElement).value;
            setFormValues((prevFormValues: any) => ({ 
                ...prevFormValues, 
                [pc]: value 
            }));
            // If the country field changes, fetch regions and update state select
            if (pc === 'country' || pc === 's_country') {
                const selectElement = event.target as HTMLSelectElement;
                clearStateSelect(selectElement);
                await getRegions(value, selectElement);
                // Reset state and city in formValues
                if (pc === 'country') {
                    setFormValues((prevFormValues: any) => ({ 
                        ...prevFormValues, 
                        state: '', 
                        city: '',
                        city_id: ''
                    }));
                } else if (pc === 's_country') {
                    setFormValues((prevFormValues: any) => ({ 
                        ...prevFormValues, 
                        s_state: '', 
                        s_city: '',
                        s_city_id: prevFormValues.sameShippingBilling ? prevFormValues.city_id : ''
                    }));
                }
            }

            // If the state field changes, reset city and city_id in formValues
            if (pc === 'state' || pc === 's_state') {
                setFormValues((prevFormValues: any) => ({
                    ...prevFormValues,
                    [pc === 'state' ? 'city' : 's_city']: '',
                    [pc === 'state' ? 'city_id' : 's_city_id']: prevFormValues.sameShippingBilling ? prevFormValues.city_id : ''
                }));
                // Clear the city input field
                const cityInput = getCityInput(event.target as HTMLSelectElement);
                if (cityInput) {
                    cityInput.value = '';
                }
            }
        }
    };

    /*=========================================================
    HANDLE AUTOFILL CHANGE
    =========================================================*/
    const handleAutofillChange = _.debounce(async (event: Event) => {
        const pc = (event.target as HTMLInputElement).getAttribute('pc');
        if (pc) {
            const value = (event.target as HTMLInputElement).value;
            setFormValues((prevFormValues: any) => ({
                ...prevFormValues,
                [pc]: value
            }));
        }
    }, 500);

    /*=========================================================
    HANDLE CHECKBOX CHANGE
    =========================================================*/
    const handleSameAsChange = async (event: Event) => {
        const checkbox = event.target as HTMLInputElement;

        setFormValues((prevFormValues: any) => ({ 
            ...prevFormValues, 
            sameShippingBilling: checkbox.checked,
        }));
        
        if (checkbox.checked) {
            const shippingFieldsContainer = document.querySelector('[pc="shipping-information"]');
            if (shippingFieldsContainer) {
                shippingFieldsContainer.classList.add('hidden');
            }

            // Replicate billing fields to shipping fields, including country, state, and city
            const fieldsToReplicate = [
                { billing: 'fname', shipping: 's-fname' },
                { billing: 'lname', shipping: 's-lname' },
                { billing: 'address', shipping: 's-address' },
                { billing: 'address2', shipping: 's-address2' },
                { billing: 'postal', shipping: 's-postal' },
                { billing: 'country', shipping: 's-country' },
                { billing: 'state', shipping: 's-state' },
                { billing: 'city', shipping: 's-city' },
            ];

            // Replicate input fields
            fieldsToReplicate.forEach(({ billing, shipping }) => {
                const billingElement = document.querySelector(`[pc="${billing}"]`) as HTMLInputElement | HTMLSelectElement;
                const shippingElement = document.querySelector(`[pc="${shipping}"]`) as HTMLInputElement | HTMLSelectElement;

                if (billingElement && shippingElement) {
                    shippingElement.value = billingElement.value;
                    const event = new Event('input', { bubbles: true });
                    shippingElement.dispatchEvent(event);
                }
            });

            // Update formValues
            setFormValues((prevFormValues: any) => {
                const updatedValues = { ...prevFormValues };
                fieldsToReplicate.forEach(({ billing, shipping }) => {
                    const value = prevFormValues[billing.replace('-', '_')];
                    updatedValues[shipping.replace('-', '_')] = value;
                });
                // Copy city_id to s_city_id
                updatedValues.s_city_id = prevFormValues.city_id;
                return {
                    ...updatedValues,
                    sameShippingBilling: checkbox.checked,
                };
            });

            // Handle asynchronous waits for country and state selection
            const billingCountrySelect = document.querySelector('select[pc="country"]') as HTMLSelectElement;
            const billingStateSelect = document.querySelector('select[pc="state"]') as HTMLSelectElement;
            const shippingCountrySelect = document.querySelector('select[pc="s-country"]') as HTMLSelectElement;
            const shippingStateSelect = document.querySelector('select[pc="s-state"]') as HTMLSelectElement;

            if (billingCountrySelect && shippingCountrySelect) {
                shippingCountrySelect.value = billingCountrySelect.value;
                const countryEvent = new Event('change', { bubbles: true });
                shippingCountrySelect.dispatchEvent(countryEvent);
                await getRegions(billingCountrySelect.value, shippingCountrySelect);

                if (billingStateSelect && shippingStateSelect) {
                    shippingStateSelect.value = billingStateSelect.value;
                    const stateEvent = new Event('change', { bubbles: true });
                    shippingStateSelect.dispatchEvent(stateEvent);
                }
            }

        } else {
            const shippingFieldsContainer = document.querySelector('[pc="shipping-information"]');
            if (shippingFieldsContainer) {
                shippingFieldsContainer.classList.remove('hidden');
            }
        }
    };


    /*=========================================================
    HANDLE TERMS CHANGE
    =========================================================*/
    const handleTermsChange = async (event: Event) => {
        const checkbox = event.target as HTMLInputElement;
        setFormValues((prevFormValues: any) => ({ 
            ...prevFormValues, 
            checkTerms: checkbox.checked,
        }));
    };




    /*=========================================================
    HELPER FUNCTIONS
    =========================================================*/
    const getStateSelect = (selectElement: HTMLSelectElement) => {
        const pcValue = selectElement.getAttribute('pc');
        if (pcValue === "country") {
            return document.querySelector('[pc="state"]') as HTMLSelectElement;
        } else if (pcValue === "s-country") {
            return document.querySelector('[pc="s-state"]') as HTMLSelectElement;
        }
        return null;
    };

    const getCityInput = (selectElement: HTMLSelectElement) => {
        const pcValue = selectElement.getAttribute('pc');
        if (pcValue === 'country') {
            return document.querySelector('input[pc="city"]') as HTMLInputElement;
        } else if (pcValue === 's-country') {
            return document.querySelector('input[pc="s-city"]') as HTMLInputElement;
        }
        return null;
    };

    const initializeDisabledFields = () => {
        const fieldsToDisable = [
            'select[pc="state"]',
            'input[pc="city"]',
            'select[pc="s-state"]',
            'input[pc="s-city"]'
        ];
        fieldsToDisable.forEach(selector => {
            const element = document.querySelector(selector) as HTMLInputElement | HTMLSelectElement;
            if (element) {
                element.classList.add('disabled');
                element.disabled = true;
            }
        });
    };

    const validateAddressFields = async (data: any = null) => {
        const source = data || formValues;

        // Billing address validation
        const billingStateSelect = document.querySelector('select[pc="state"]') as HTMLSelectElement;
        const billingCityInput = document.querySelector('input[pc="city"]') as HTMLInputElement;

        if (billingStateSelect) {
            if (source.country && source.country !== '') {
                billingStateSelect.disabled = false;
                billingStateSelect.classList.remove('disabled');
            } else {
                billingStateSelect.disabled = true;
                billingStateSelect.classList.add('disabled');
                billingStateSelect.value = '';
                setFormValues((prev: any) => ({ ...prev, state: '' }));
            }
        }

        if (billingCityInput) {
            if (source.state && source.state !== '') {
                billingCityInput.disabled = false;
                billingCityInput.classList.remove('disabled');
            } else {
                billingCityInput.disabled = true;
                billingCityInput.classList.add('disabled');
                billingCityInput.value = '';
                setFormValues((prev: any) => ({ ...prev, city: '' }));
            }
        }

        // Shipping address validation
        const shippingStateSelect = document.querySelector('select[pc="s-state"]') as HTMLSelectElement;
        const shippingCityInput = document.querySelector('input[pc="s-city"]') as HTMLInputElement;

        if (shippingStateSelect) {
            if (source.s_country && source.s_country !== '') {
                shippingStateSelect.disabled = false;
                shippingStateSelect.classList.remove('disabled');
            } else {
                shippingStateSelect.disabled = true;
                shippingStateSelect.classList.add('disabled');
                shippingStateSelect.value = '';
                setFormValues((prev: any) => ({ ...prev, s_state: '' }));
            }
        }

        if (shippingCityInput) {
            if (source.s_state && source.s_state !== '') {
                shippingCityInput.disabled = false;
                shippingCityInput.classList.remove('disabled');
            } else {
                shippingCityInput.disabled = true;
                shippingCityInput.classList.add('disabled');
                shippingCityInput.value = '';
                setFormValues((prev: any) => ({ ...prev, s_city: '' }));
            }
        }
    };

    useEffect(() => {
        if (isInitialized) {
            validateAddressFields();
        }
    }, [formValues.country, formValues.state, formValues.s_country, formValues.s_state]);

    

    return null;
}
